<template>
  <b-container fluid>
     <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:headerTitle>
               <h4 class="card-title">{{ $t('farm_config.pending_distribution') }} {{ $t('globalTrans.details') }}</h4>
            </template>
            <template v-slot:headerAction>
                <router-link class="btn btn-sm btn-primary" :to="{name: 'incentive_grant_service.farm_machinery.allocation.distribution_list'}" variant="primary btn_add_new">
                    <i class="fa fa-arrow-left"></i> {{ $t('farm_config.pending_distribution') }} {{ $t('globalTrans.list') }}
                </router-link>
            </template>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div class="mx-2">
                           <b-table-simple small resonsive borderless v-if="detailsData.circular">
                              <b-tr v-if="upazilla_id">
                                  <b-th width="15%">{{ $t('globalTrans.upazila') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                      {{ getUpazilaName(upazilla_id) }}
                                  </b-td>
                                  <b-th width="15%">{{ $t('globalTrans.fiscal_year') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                      {{ getFiscalYearName(detailsData.circular.fiscal_year_id) }}
                                  </b-td>
                              </b-tr>
                               <b-tr>
                                  <b-th width="15%">{{ $t('farm_config.season') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                      {{ getSeasonName(detailsData.circular.season_id) }}
                                  </b-td>
                                  <b-th width="15%">{{ $t('farm_config.circular') }}</b-th>
                                  <b-th width="2%">:</b-th>
                                  <b-td>
                                     <span v-if="detailsData.circular">{{ currentLocale === 'en' ? detailsData.circular.circular_name :  detailsData.circular.circular_name_bn }}</span>
                                  </b-td>
                              </b-tr>
                          </b-table-simple>
                        </div>
                        <!-- allocation upazila -->
                        <div  class="mx-2">
                          <h5><b>{{$t('farm_allocation.allocation_info')}} :</b></h5>
                            <b-table-simple striped bordered small class="mt-2">
                              <b-thead>
                                  <tr class="text-center" v-if="Object.keys(detailsData.instrumentList).length > 0">
                                        <b-th v-for="(machine, machineIndex) in detailsData.instrumentList" :key="'mechine-' + machineIndex" class="text-center">
                                            {{ currentLocale === 'en' ? machine.name : machine.name_bn }}
                                        </b-th>
                                  </tr>
                              </b-thead>
                              <b-tbody>
                                  <template v-if="Object.keys(detailsData.instrumentList).length > 0">
                                      <b-tr>
                                          <b-td class="text-center" v-for="(machineType, instrumentIndex) in detailsData.instrumentList" :key="'mechine-item-' + instrumentIndex">
                                            {{$n(machineType.quantity)}}
                                          </b-td>
                                      </b-tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                          <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                  </template>
                              </b-tbody>
                          </b-table-simple>
                        </div>
                       <!-- distributed upazila -->
                        <div  class="mx-2">
                          <h5><b>{{$t('farm_allocation.distributed_qty')}} :</b></h5>
                            <b-table-simple striped bordered small class="mt-2">
                              <b-thead>
                                  <tr class="text-center" v-if="Object.keys(detailsData.instrumentList).length > 0">
                                        <b-th v-for="(machine, machineIndex) in detailsData.instrumentList" :key="'mechine-' + machineIndex" class="text-center">
                                            {{ currentLocale === 'en' ? machine.name : machine.name_bn }}
                                        </b-th>
                                  </tr>
                              </b-thead>
                              <b-tbody>
                                  <template v-if="Object.keys(detailsData.instrumentList).length > 0">
                                      <b-tr>
                                          <b-td class="text-center" v-for="(machineType, instrumentIndex) in detailsData.instrumentList" :key="'mechine-item-' + instrumentIndex">
                                            {{$n(machineType.distributed_qty)}}
                                          </b-td>
                                      </b-tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                          <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                  </template>
                              </b-tbody>
                          </b-table-simple>
                        </div>
                        <!-- farmer list -->
                         <div  class="mx-2">
                          <h5><b>{{$t('farm_allocation.farmer_list')}} :</b></h5>
                            <b-table-simple striped bordered small class="mt-2" v-if="Object.keys(detailsData.farmarList).length > 0">
                              <b-thead>
                                  <tr class="text-center">
                                        <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.name') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.father_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.mobile') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.nid') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.machine_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.brand') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.union') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_allocation.priority') }}</b-th>
                                         <b-th class="text-center">{{ $t('globalTrans.action') }}</b-th>
                                  </tr>
                              </b-thead>
                              <b-tbody>
                                  <template v-if="Object.keys(detailsData.farmarList).length > 0">
                                      <b-tr v-for="(farmer, farmerIndex) in detailsData.farmarList" :key="'farmer-list-' + farmerIndex">
                                            <b-td  class="text-center">{{ $n(farmerIndex + 1) }}</b-td>
                                            <b-td class="text-center">{{ currentLocale === 'en' ? farmer.farmar_name_en : farmer.farmar_name_bn }}</b-td>
                                            <b-td class="text-center">{{ currentLocale === 'en' ? farmer.father_name : farmer.father_name_bn }}</b-td>
                                            <b-td class="text-center"> {{ ($i18n.locale == 'bn' ? '০': '0') + $n(farmer.mobile_no, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ $n(farmer.nid_no, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ getInstrumentName(farmer.instrument_id)}}</b-td>
                                            <b-td class="text-center">{{ getBrandName(farmer.brand_id)}}</b-td>
                                            <b-td class="text-center">{{ farmer.union_id ? getUnionName(farmer.union_id) : '-'}}</b-td>
                                            <b-td class="text-center">{{ $n(farmer.sorting) }}</b-td>
                                            <b-td class="text-center" style="width:20%">
                                              <b-button type="button" v-if="checkFarmer(detailsData.approvedFarmers, farmer.farmer_id) && assignOptionVisible(farmer.instrument_id)" v-b-modal.modal-5 class="btn-sm mr-2" variant="primary" @click="assignToSupplier(farmer)">{{$t('farm_config.assign')}}</b-button>
                                              <!-- <b-button type="button" class="btn-sm" variant="danger" @click="farmerApplicationWidthdraw(farmer)">{{$t('farm_config.withdraw')}}</b-button> -->
                                            </b-td>
                                      </b-tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                          <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                  </template>
                              </b-tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      </b-overlay>
        <b-modal id="modal-5" size="xl" :title="$t('farm_config.assign') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <AssignDistribution :item="item" :instrumentList="detailsData.instrumentList" :key="item"></AssignDistribution>
        </b-modal>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { allocationDistributionDetailsApi, farmerApplicationWidthdrawApi } from '../../api/routes'
import AssignDistribution from './AssignDistribution.vue'
export default {
  name: 'FormLayout',
  components: { AssignDistribution },
  created () {
    this.getData(this.id)
  },
  data () {
    return {
      detailsData: {
        circular: {},
        machineTpeList: [],
        instrumentList: [],
        farmarList: []
      },
      item: {},
      slOffset: 1,
      loadMechineType: false,
      upazilla_id: 0
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
        return this.$i18n.locale
    },
    id () {
      return this.$route.params.id
    },
    seasonList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
      ]
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
    },
    loading: function (newVal, oldVal) {
      if (newVal) {
        this.getData(this.id)
      }
    }
  },
  methods: {
    checkFarmer (farmerList, farmerID) {
        if (farmerList[0].approved_farmers && farmerList[0].approved_farmers.includes(farmerID)) {
            return true
        }
        return false
    },
    getDistrictName (districtId) {
      const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUpazilaName (upazilaId) {
      const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUnionName (unionId) {
      const obj = this.$store.state.commonObj.unionList.find(el => el.value === parseInt(unionId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      }
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getInstrumentName (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getBrandName (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSeasonName (id) {
      const obj = this.seasonList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async getData () {
      const params = {
        circular_id: this.id
      }

      if (this.authUser.role_id !== 1 && this.authUser.is_org_admin === 0) {
          if (this.authUser.office_detail.upazilla_id) {
              params.upazilla_id = this.authUser.office_detail.upazilla_id
              this.upazilla_id = this.authUser.office_detail.upazilla_id
          }
      }

      let result = null
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, allocationDistributionDetailsApi, params)
      if (result.success) {
          this.detailsData = result.data
      } else {
        window.vm.$toast.error({
            title: 'Error',
            message: 'Allocation not Completed!! Please, try again.'
        })
        this.detailsData = {}
        return this.$router.go(-1)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    farmerApplicationWidthdraw (item) {
        window.vm.$swal({
            title: window.vm.$t('farm_config.withdraw_confirm_message'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                RestApi.deleteData(incentiveGrantServiceBaseUrl, `${farmerApplicationWidthdrawApi}/${item.application_id}`).then(response => {
                    if (response.success) {
                        window.vm.$toast.success({
                            title: window.vm.$t('globalTrans.success'),
                            message: window.vm.$t('globalTrans.update_msg'),
                            color: '#D6E09B'
                        })
                        this.getData()
                    } else {
                        window.vm.$toast.error({
                            title: 'Error',
                            message: 'Operation failed! Please, try again.'
                        })
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                })
            }
      })
    },
    assignToSupplier (item) {
      this.item = item
    },
    assignOptionVisible (instrumentId) {
      const obj = this.detailsData.instrumentList.find(item => item.instrument_id === instrumentId)
      if (obj) {
        if (obj.quantity > obj.distributed_qty) {
          return true
        }
        return false
      } else {
        return false
      }
    }
  }
}
</script>
