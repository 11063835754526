<template>
  <b-container fluid>
     <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                      <b-form @submit.prevent="handleSubmit(submitAssignData)" @reset.prevent="reset" >
                                          <b-row>
                                            <!-- supplier type -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Origin Type" vid="type" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="type"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_allocation.origin_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                       <b-form-select
                                                          plain
                                                          v-model="formData.type"
                                                          id="fiscal_year_id"
                                                          :options="typeList"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                          <template v-slot:first>
                                                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                          </template>
                                                      </b-form-select>
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- supplier_id -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Supplier Name" vid="supplier_machinery_info_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="supplier_machinery_info_id"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_allocation.supplier')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="supplier_machinery_info_id"
                                                          v-model="formData.supplier_machinery_info_id"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierList
                                                          :placeholder="$t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!-- mechine type -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Machine Type Name" vid="machine_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="machine_type_id"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_config.machine_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="machine_type_id"
                                                        disabled
                                                          v-model="formData.machine_type_id"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.mechineTypeList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!-- mechine name -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Instrument Name" vid="instrument_name_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="instrument_name_id"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_config.machine_name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="instrument_name_id"
                                                        disabled
                                                          v-model="formData.instrument_name_id"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.instrumentNameList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- brand list -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Brand" vid="brand_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="brand_id"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_config.brand')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="brand_id"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.brand_id"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.brandList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- country origin list -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Country Origin" vid="county_origin_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="county_origin_id"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_config.country_origin_name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="county_origin_id"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.county_origin_id"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= originCountryList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!--manufacture country list -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Manufacturer Country" vid="county_manufacturer_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="county_manufacturer_id"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_config.country_prepared_name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="county_manufacturer_id"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.county_manufacturer_id"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= manufactureCountryList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!-- model -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Model" vid="model" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="model"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_allocation.model')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="model"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.model"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.modelList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!--extend model -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Extend Model" vid="extend_model" :rules="{required:false}">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="extend_model"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_allocation.extend_model')}}
                                                        </template>
                                                        <v-select name="extend_model"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.extend_model"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.extendModelList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                           <!-- engine power -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Engine Power" vid="engine_power" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="engine_power"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_allocation.engine_power')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="engine_power"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.engine_power"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.enginePowerList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!--engine_no -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Engine No" vid="engine_no" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="engine_no"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_allocation.engine_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="engine_no"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.engine_no"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.engineNoList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!--chasis no -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Chasis No" vid="chasis_no" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="chasis_no"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_allocation.chasis_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="chasis_no"
                                                          :disabled="supplierDropdownLoader"
                                                          v-model="formData.chasis_no"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= supplierDropwownData.chasisNoList
                                                          :placeholder="supplierDropdownLoader ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- distributed date -->
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Distributed Date" vid="distributed_date" rules="required">
                                                <b-form-group
                                                  class="row"
                                                  label-cols-sm="4"
                                                  label-for="distributed_date"
                                                  slot-scope="{ valid, errors }"
                                                  >
                                                  <template v-slot:label>
                                                    {{$t('farm_allocation.distributed_date')}} <span class="text-danger">*</span>
                                                  </template>
                                                  <flat-pickr class="form-control"
                                                    v-model="formData.distributed_date"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                  ></flat-pickr>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <!-- mechine_photo -->
                                           <b-col lg="6" sm="12">
                                              <ValidationProvider name="Engine Photo" vid="mechine_photo">
                                                <b-form-group class="row" label-cols-sm="4" label-for="mechine_photo" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('farm_allocation.engine_photo') }}
                                                  </template>
                                                  <b-form-file
                                                    id="mechine_photo"
                                                    v-model="formData.mechine_photo"
                                                    :placeholder="$t('common_config.file_chosen')"
                                                    @change="onFileChangeEnginePhoto"
                                                    accept="image/*"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                  </b-form-file>
                                                  <small><i :title="$t('farm_allocation.image_validation')" class="ri-folder-info-line"></i> <b>{{$t('farm_allocation.image_validation')}}</b></small>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <!-- handover photo -->
                                            <b-col lg="6" sm="12">
                                              <ValidationProvider name="Handover Photo" vid="handover_photo">
                                                <b-form-group class="row" label-cols-sm="4" label-for="handover_photo" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('farm_allocation.handover_photo') }}
                                                  </template>
                                                  <b-form-file
                                                    id="handover_photo"
                                                    v-model="formData.handover_photo"
                                                    :placeholder="$t('common_config.file_chosen')"
                                                    @change="onFileChangeHandoverPhoto"
                                                    accept="image/*"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                  </b-form-file>
                                                  <small><i :title="$t('farm_allocation.image_validation')" class="ri-folder-info-line"></i><b> {{$t('farm_allocation.image_validation')}}</b></small>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                            <!-- acknowledgement -->
                                              <b-col lg="6" sm="12">
                                              <ValidationProvider name="Acknowledgement Attachment" vid="acknowledgement_attachment">
                                                <b-form-group class="row" label-cols-sm="4" label-for="acknowledgement_attachment" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('farm_allocation.acknowledgement') }}
                                                  </template>
                                                  <b-form-file
                                                    id="acknowledgement_attachment"
                                                    v-model="formData.acknowledgement_attachment"
                                                    :placeholder="$t('common_config.file_chosen')"
                                                    accept="pdf/*"
                                                    @change="onFileChangeAcknowledgement"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                  </b-form-file>
                                                  <small><i :title="$t('farm_allocation.pdf_validation')" class="ri-folder-info-line"></i><b> {{$t('farm_allocation.pdf_validation')}}</b></small>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <div class="row mt-2">
                                              <div class="col-sm-3"></div>
                                              <div class="col text-right">
                                                <b-button @click="submitAssignData" variant="primary" class="mr-2">{{ $t('farm_allocation.distributed') }}</b-button>
                                              </div>
                                          </div>
                                      </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
          </iq-card>
        </b-col>
      </b-row>
      </b-overlay>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionAssignApi, distributionAssignSupplierListApi, distributionSupplierDropdownListApi } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['item', 'instrumentList'],
  data () {
    return {
      formData: {
        checkStatus: false,
        type: 2,
        supplier_machinery_info_id: '',
        instrument_name_id: '',
        machine_type_id: '',
        engine_power: '',
        model: '',
        extend_model: '',
        engine_no: '',
        chasis_no: '',
        brand_id: '',
        county_manufacturer_id: '',
        county_origin_id: '',
        distributed_date: new Date().toISOString().slice(0, 10),
        mechine_photo: '',
        handover_photo: '',
        acknowledgement_attachment: ''
      },
      mechine_photo: '',
      handover_photo: '',
      acknowledgement_attachment: '',
      slOffset: 1,
      loadMechineType: false,
      upazilla_id: 0,
      supplierDropwownData: {
        mechineTypeList: [],
        instrumentNameList: [],
        enginePowerList: [],
        modelList: [],
        extendModelList: [],
        engineNoList: [],
        chasisNoList: [],
        brandList: [],
        manufactureCountryList: [],
        originCountryList: []
      },
      supplierDropdownLoader: false,
      supplierList: [],
      originCountryList: [],
      manufactureCountryList: [],
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
        return this.$i18n.locale
    },
    id () {
      return this.$route.params.id
    },
    seasonList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
      ]
    },
    typeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'বিদেশী' : 'Foreign' }
      ]
      return list
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      this.getAssignSupplierList()
      this.getSupplierDropdownList()
    },
    'formData.supplier_machinery_info_id': function (newVal, oldVal) {
        if (newVal) {
            this.formData.instrument_name_id = null
            this.formData.machine_type_id = null
            this.formData.engine_power = null
            this.formData.model = null
            this.formData.extend_model = null
            this.formData.engine_no = null
            this.formData.chasis_no = null
            this.formData.brand_id = null
            this.formData.county_manufacturer_id = null
            this.formData.county_origin_id = null
        }
        this.checkStatus = true
        this.getSupplierDropdownList()
    },
    'formData.type': function (newVal, oldVal) {
      this.getAssignSupplierList()
    },
    'formData.brand_id': function (newVal, oldVal) {
        if (newVal) {
          this.getCountryOriginList()
          this.getCountryManufactureList()
          this.getSupplierDropdownList()
        }
    },
    'formData.engine_power': function (newVal, oldVal) {
        if (newVal) {
          this.getCountryOriginList()
          this.getCountryManufactureList()
          this.getSupplierDropdownList()
        }
    },
    'formData.model': function (newVal, oldVal) {
        if (newVal) {
          this.getCountryOriginList()
          this.getCountryManufactureList()
          this.getSupplierDropdownList()
        }
    },
    'formData.extend_model': function (newVal, oldVal) {
        if (newVal) {
          this.getCountryOriginList()
          this.getCountryManufactureList()
          this.getSupplierDropdownList()
        }
    },
    'formData.engine_no': function (newVal, oldVal) {
        if (newVal) {
          this.getCountryOriginList()
          this.getCountryManufactureList()
          this.getSupplierDropdownList()
        }
    },
    'formData.county_manufacturer_id': function (newVal, oldVal) {
        if (newVal) {
          this.getCountryOriginList()
          this.getCountryManufactureList()
          this.getSupplierDropdownList()
        }
    },
    'formData.county_origin_id': function (newVal, oldVal) {
        if (newVal) {
          this.getCountryOriginList()
          this.getCountryManufactureList()
          this.getSupplierDropdownList()
        }
    }
  },
  created () {
    this.getAssignSupplierList()
    this.getSupplierDropdownList()
    this.getUnionhaorCostalArea()
  },
  methods: {
    getDistrictName (districtId) {
      const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUpazilaName (upazilaId) {
      const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
      if (obj !== 'undefinded') {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getInstrumentName (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getBrandName (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSeasonName (id) {
      const obj = this.seasonList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async submitAssignData () {
            this.formData.application_id = this.item.application_id
            const allocatedInstrumentObj = this.instrumentList.find(item => item.instrument_id === this.item.instrument_id)
            if (allocatedInstrumentObj !== undefined) {
              this.formData.upazila_instrument_qty_id = allocatedInstrumentObj.id
            } else {
              this.formData.upazila_instrument_qty_id = null
            }

            var check = await this.$refs.form.validate()
            let result = ''
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })

            if (check) {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributionAssignApi, this.formData)

                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                this.loading = false
                if (result.success) {
                    this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    this.$bvModal.hide('modal-5')
                } else {
                    this.$toast.error({
                        title: 'Success',
                        message: result.message,
                        color: '#D6E09B'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            } else {
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
            }
    },
    async getAssignSupplierList () {
      this.formData.lang = this.currentLocale
      let result = null
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributionAssignSupplierListApi, this.formData)
      if (result.success) {
          this.supplierList = result.data
      } else {
        this.supplierList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async getSupplierDropdownList () {
        this.formData.lang = this.currentLocale
        let result = null
        this.supplierDropdownLoader = true
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributionSupplierDropdownListApi, this.formData)
        if (result.success) {
            this.supplierDropwownData = result.data
            this.getCountryOriginList()
            this.getCountryManufactureList()
        } else {
            this.supplierDropwownData = {
            mechineTypeList: [],
            instrumentNameList: [],
            enginePowerList: [],
            modelList: [],
            extendModelList: [],
            engineNoList: [],
            chasisNoList: [],
            brandList: [],
            manufactureCountryList: [],
            originCountryList: []
            }
        }
        this.supplierDropdownLoader = false
        if (this.item && this.checkStatus) {
            const checkMachineTypeExit = this.supplierDropwownData.mechineTypeList.find(item => item.value === this.item.machine_type_id)
            const checkInstrumentNameExit = this.supplierDropwownData.instrumentNameList.find(item => item.value === this.item.instrument_id)
            if (checkMachineTypeExit) {
                this.formData.machine_type_id = this.item.machine_type_id
            }
            if (checkInstrumentNameExit) {
                this.formData.instrument_name_id = this.item.instrument_id
            }
        }
    },
    getCountryOriginList () {
      this.originCountryList = []
      this.supplierDropwownData.originCountryList.forEach(element => {
        var item = this.$store.state.commonObj.countryList.find(item => item.value === element.value)
        if (item !== undefined) {
          item.text = this.currentLocale === 'en' ? item.text_en : item.text_bn
          this.originCountryList.push(item)
        }
      })
    },
    getCountryManufactureList () {
      this.manufactureCountryList = []
      this.supplierDropwownData.manufactureCountryList.forEach(element => {
        var item = this.$store.state.commonObj.countryList.find(item => item.value === element.value)
        if (item !== undefined) {
          item.text = this.currentLocale === 'en' ? item.text_en : item.text_bn
          this.manufactureCountryList.push(item)
        }
      })
    },
    onFileChangeEnginePhoto (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
          this.formData.mechine_photo = event.target.result
      }
      reader.readAsDataURL(selectedPhoto)
    },
    onFileChangeHandoverPhoto (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
          this.formData.handover_photo = event.target.result
      }
      reader.readAsDataURL(selectedPhoto)
    },
    onFileChangeAcknowledgement (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
          this.formData.acknowledgement_attachment = event.target.result
      }
      reader.readAsDataURL(selectedPhoto)
    },
    getUnionhaorCostalArea () {
      if (this.item.union_id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(this.item.union_id))
        if (obj !== 'undefinded') {
          this.formData.haor_costal_area = obj.haor_costal_area
        }
      } else {
        this.formData.haor_costal_area = 0
      }
    }
  }
}
</script>
